import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import DrawerAppBar from './components/DrawerAppBar';
import { Box, CssBaseline, Modal, ThemeProvider, createTheme } from '@mui/material';
import Home from './pages/Home';
const Services = lazy(() => import('./pages/Services'));
const About = lazy(() => import('./pages/About'));
const Location = lazy(() => import('./pages/Location'));

export const loading = (
  <div style={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    <img src="img/loading.gif" alt="loading spinner" />
  </div>
)

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "black",
          color: "white",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 0
        },
        outlined: {
          borderRadius: 0
        },
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#19506c'
    },
    secondary: {
      main: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: '"Space Mono", monospace',
    fontSize: 16,

    button: {
      textTransform: 'capitalize'
    },
  }
});

type AppContextValues = {
  webpSupported: boolean,
  isReady: boolean
}

export const AppContext = React.createContext<AppContextValues>({ webpSupported: true, isReady: false });

function App() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [webpSupported, setWebpSupported] = React.useState(true);
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      var result = (img.width > 0) && (img.height > 0);
      setWebpSupported(result)
      setIsReady(true)
    };
    img.onerror = function () {
      setWebpSupported(false)
      setIsReady(true)
    };
    img.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=="
  }, [])

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    height: 520,
    bgcolor: 'background.paper',
    border: 0
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <main>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          disableScrollLock
        >
          <Box sx={modalStyle}>
            <iframe loading="lazy" src="https://gabrielamassagetherapy.clinicsense.com/book/" title="Book an appointment with Gabriela" width="100%" height="100%"></iframe>
          </Box>
        </Modal>
        <DrawerAppBar handleOpenModal={handleOpenModal}>
          <AppContext.Provider value={{ webpSupported: webpSupported, isReady: isReady }}>
            <Routes>
              <Route index element={<Suspense fallback={loading}><Home handleOpenModal={handleOpenModal} /></Suspense>} />
              <Route path="services" element={<Suspense fallback={loading}><Services handleOpenModal={handleOpenModal} /></Suspense>} />
              <Route path="about" element={<Suspense fallback={loading}><About handleOpenModal={handleOpenModal} /></Suspense>} />
              <Route path="location" element={<Suspense fallback={loading}><Location handleOpenModal={handleOpenModal} /></Suspense>} />
            </Routes>
          </AppContext.Provider>
        </DrawerAppBar>
      </main>
    </ThemeProvider>
  );
}

export default App;
