import { useRef, useEffect, useState, MutableRefObject } from "react";

export default function useVisibilityOnce<T>(
    offset = 0,
): [boolean, MutableRefObject<Element | undefined>] {
    const [isVisible, setIsVisible] = useState(false);
    const [hasBeenVisible, setHasBeenVisible] = useState(false); // New state to track if element has been visible
    const currentElement = useRef<Element>();

    const onScroll = () => {
        if (hasBeenVisible) {
            setIsVisible(true);
            return;
        }
        if (!currentElement.current) { // Stop checking if already visible
            setIsVisible(false);
            return;
        }
        const top = currentElement.current.getBoundingClientRect().top;
        const visible = top + offset >= 0 && top - offset <= window.innerHeight;
        if (visible) {
            setHasBeenVisible(true); // Set the flag indicating the element has been seen
        }
        setIsVisible(visible);
    };

    useEffect(() => {
        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    }, [hasBeenVisible]); // Effect depends on the flag hasBeenVisible

    return [isVisible, currentElement];
}