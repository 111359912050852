import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../App";

function Footer(props: { handleOpenModal: () => void }) {
    const { webpSupported, isReady } = useContext(AppContext)

    return (
        <Box
            sx={{
                position: 'relative',
                color: 'white',
                backgroundColor: '#283e4a',
                paddingY: '3rem',
                paddingX: { xs: '2rem', md: '8rem', lg: '12rem', xl: '20rem', xxl: '40rem' },
            }}
        >
            <Box style={{
                backgroundImage: `url('${isReady ? (webpSupported ? 'imgs/header-massage.webp' : 'img/header-massage.jpg') : 'img/placeholder.png'}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPositionX: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                filter: 'grayscale(100%) brightness(30%)'
            }}>
            </Box>
            <Grid container spacing={{ xs: 5, md: 20 }} alignItems="center" style={{
                position: 'relative',
                top: 0,
                left: 0,
            }}>
                <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="start" flexWrap="nowrap" alignItems="center" alignContent="center">
                        <Typography style={{ fontSize: '1.5rem', fontWeight: '700' }}>
                            Om Healing Massage
                        </Typography>
                        {/* <a href="https://www.instagram.com/omhealingmassage" aria-label="Go to Instagram in new tab" target="_blank"><img src="/img/instagram.png" loading="lazy" width="25" style={{ marginLeft: 5, display: 'block' }} alt="Instagram logo" /></a> */}
                    </Box>
                    <br />
                    <Typography style={{ fontSize: '1rem' }}>
                        &#051;&#048;&#048;&#051;&#032;&#056;&#048;&#116;&#104;&#032;&#065;&#118;&#101;&#032;&#083;&#069;,<br />
                        &#077;&#101;&#114;&#099;&#101;&#114;&#032;&#073;&#115;&#108;&#097;&#110;&#100;,&#032;&#087;&#065;&#032;&#057;&#056;&#048;&#052;&#048;<br />
                        (&#052;&#050;&#053;)&#032;&#050;&#054;&#051;&#045;&#048;&#053;&#051;&#049;
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={{ xs: 'left', md: 'right' }}>
                    <Typography style={{ fontSize: '1rem' }}>
                        <Link to="/" style={{ color: '#bdbdbd' }}>Home</Link><br />
                        <Link to="/services" style={{ color: '#bdbdbd' }}>Services</Link><br />
                        <Link to="/about" style={{ color: '#bdbdbd' }}>About Me</Link><br />
                        <Link to="/location" style={{ color: '#bdbdbd' }}>Location</Link><br />
                        <a style={{ color: '#bdbdbd', textDecoration: 'underline', cursor: 'pointer' }} onClick={props.handleOpenModal}>Book Appointment</a><br />
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Footer;