import { Suspense, lazy, useContext } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import useVisibilityOnce from '../hooks/useVisibilityOnce';
import { AppContext, loading } from '../App';

const MobileMap = lazy(() => import('../components/MobileMap'));
const LargeMap = lazy(() => import('../components/LargeMap'));

function Home(props: { handleOpenModal: () => void }) {
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { webpSupported, isReady } = useContext(AppContext)

  const [servicesShown, servicesRef] = useVisibilityOnce<HTMLDivElement>()

  const [locationShown, locationRef] = useVisibilityOnce<HTMLDivElement>()

  return (
    <Box sx={{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      backgroundColor: 'black'
    }}>
      <Box
        sx={{
          backgroundColor: 'black',
          height: { xs: '100vh', md: '500px', xl: '800px' },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingTop: 10,
          overflow: 'hidden'
        }}>
        <video
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: 'inherit',
            objectFit: 'cover',
            transition: 'all 2s'
          }}
          src={`${isReady ? (isMobile ? `mobile_vid.mp4` : `desktop_vid.mp4`) : ''}`}
          autoPlay loop playsInline muted></video>
        <Typography className="fade-in" variant="h1" sx={{ marginBottom: 1, fontWeight: 700, color: 'white', fontFamily: '"Space Mono", monospace', fontSize: { xs: '2rem', md: '2.2rem' } }}>
          Om Healing Massage
        </Typography>
        <Typography className="fade-in" variant="h2" sx={{ color: 'white', fontFamily: '"Space Mono", monospace', fontSize: { xs: '1rem', md: '1.1rem' } }}>
          Experience wellness with Gabriela
        </Typography>
        <Button
          className="fade-in"
          color="secondary"
          variant="outlined"
          key="Book Appointment"
          sx={
            {
              color: '#3BBA9C',
              fontFamily: '"Space Mono", monospace',
              borderColor: '#3BBA9C',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              marginTop: 4,
              ':hover': {
                borderColor: '#3BBA9C',
                textDecoration: 'underline',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
              },
              fontSize: { xs: '1rem', md: '1.1rem' },
              padding: '0.5rem 1rem'
            }
          }
          size="large"
          onClick={props.handleOpenModal}>
          Book Appointment
        </Button>
      </Box>
      <Grid container height={400}>
        <Grid item xs={12} md={6} style={{ position: 'relative' }}>
          <Box style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundImage: `url('${isReady ? (webpSupported ? `imgs/massage-1.webp` : `img/massage-1.jpg`) : 'img/placeholder.png'}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
            filter: 'sepia(100%) brightness(15%) hue-rotate(170deg)'
          }}></Box>
          <Box className="fade-in delay-1" display="flex" justifyContent="center" alignItems="center" fontSize="2.5rem" style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            fontFamily: '"Space Mono", monospace',
            color: '#3BBA9C'
          }}>
            healing.
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ position: 'relative' }}>
          <Box style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: '#3BBA9C',
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
          }}></Box>
          <Box className="fade-in delay-1" display="flex" flexDirection="column" justifyContent="center" alignItems={{ xs: 'center', md: 'flex-start' }} textAlign={{ xs: 'center', md: 'left' }} fontSize={{ xs: '1rem', md: '1.1rem' }}
            paddingX={{ xs: 2, md: 5, lg: 10, xl: 20, xxl: 30 }}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              fontFamily: '"Space Mono", monospace',
              color: '#2E3047'
            }}>
            Located on Mercer Island, Gabriela offers a wide-range of therapeutic massage services to elevate your wellness.
            <br /> <br />
            <Button onClick={() => navigate("/services")} variant="text" style={{ padding: 0, color: '#FFFFFF', textDecoration: 'underline' }} sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>
              Learn more
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box
        ref={servicesRef}
        textAlign="center"
        display="flex"
        justifyContent="center"
        fontSize={{ xs: '1rem', md: '1.1rem' }}
        paddingX={{
          xs: 4, sm: 15, md: 20, lg: 30, xl: 50, xxl: 60
        }}
        paddingY={{
          xs: 10, md: 7, lg: 12, xl: 20, xxl: 25
        }}
        style={{
          color: '#3BBA9C',
          backgroundColor: '#2E3047'
        }}>
        <Box border="1px solid #3BBA9C"
          maxWidth="700px"
          paddingX={{
            xs: 3, sm: 10, lg: 20
          }}
          paddingY={{
            xs: 5, sm: 10
          }}
          style={{
            opacity: servicesShown ? 1 : 0,
            transition: 'opacity 1.5s',
          }}
        >
          Om Healing Massage offers a variety of services, including deep tissue massage, cupping, and the Himalayan Salt Stone experience.
        </Box>
      </Box>
      <Box
        padding={2}
        textAlign="center"
        sx={{
          backgroundColor: '#3BBA9C',
          width: '100%',
          color: 'white',
          fontSize: { xs: '1rem', md: '1.1rem' },
          fontStyle: 'italic'
        }}
      >
        <Box style={{
          opacity: locationShown ? 1 : 0,
          transition: 'opacity 1.5s',
        }}>
          Come try the Love Yourself Day, with an infusion of organic rose oil and the best tools
        </Box>
        
      </Box>
      <Box
        sx={{
          color: 'white',
          backgroundColor: '#2E3047',
          paddingX: { xs: '2rem', md: '8rem', lg: '12rem', xl: '20rem', xxl: '40rem' },
          paddingY: { xs: '4rem', md: '8rem' },
          fontSize: '1.3rem',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box ref={locationRef} />
        <Box style={{
          opacity: locationShown ? 1 : 0,
          transition: 'opacity 1.5s',
          filter: 'grayscale(100%) brightness(80%)'
        }}>
          {isReady &&
            <a href="https://www.google.com/maps/place/Om+Healing+Massage,+3003+80th+Ave+SE,+Mercer+Island,+WA+98040/@47.5834206,-122.2305446,18z/data=!4m15!1m8!3m7!1s0x54906bb9a48980bb:0x4231e28a9be06e34!2s3003+80th+Ave+SE,+Mercer+Island,+WA+98040!8m2!3d47.5833663!4d-122.2320681!10e3!16s%2Fg%2F11bw3_7k6f!3m5!1s0x54906b40931e0315:0xc87373bf7c8d04f5!8m2!3d47.5833663!4d-122.2320681!16s%2Fg%2F11vt4mth0f" target="_blank">
              <img src={webpSupported ? 'imgs/map.webp' : 'img/map.png'} alt="Google map of location" width={isMobile ? '100%' : 'auto'} />
            </a>
          }

        </Box>
        <Box maxWidth={390} style={{
          opacity: locationShown ? 1 : 0,
          transition: 'opacity 1.5s'
        }}>
          <Typography style={{ fontFamily: '"Space Mono", monospace' }} sx={{ fontSize: { xs: '1rem', md: '1.1rem' }, marginY: '2rem' }}>
            My massage studio is located inside of Dulce Lash Lounge on Mercer Island.
          </Typography>
          <Button variant="outlined" size="medium" style={{ color: '#3BBA9C', borderColor: '#3BBA9C' }} sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }} onClick={() => navigate("/location")}>Location Details</Button>
        </Box>
      </Box>
      <Footer handleOpenModal={props.handleOpenModal} />
    </Box>
  )
}

export default Home;