import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Fade } from '@mui/material';
import useScrollPosition from '../hooks/useScrollPosition';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
  handleOpenModal: () => void;
}

const drawerWidth = 240;
const navItemsMap = {
  'Home': '/',
  'Services': '/services',
  'About Me': '/about',
  'Location': '/location'
}

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const scrollPosition = useScrollPosition();

  const navigate = useNavigate()

  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {Object.entries(navItemsMap).map(([item, path]) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(path)}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem key="Book Appointment" disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={props.handleOpenModal}>
            <ListItemText primary="Book Appointment" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <Fade in={scrollPosition < 20}>
        <AppBar id="app-bar" component="nav" style={{
          width: '100%',
          color: 'white',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          transition: "all 2s ease",
          WebkitTransition: "all 2s ease",
          MozTransition: "all 2s ease",
        }} sx={{ paddingY: 3, paddingX: { xs: 1, md: 6 } }}>
          <Toolbar>
            <Box
              sx={{ flexGrow: 1, display: 'block' }}
            >
            </Box>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              style={{ padding: 0 }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Fade>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: 'block',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" style={{ width: '100%' }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
